import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, Input, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { getCatalogs } from "../../api/catalogs";
import { getCaps, getCities, getPrefixes, getProvinces, getRegions } from "../../api/istat";
import { useSwallowLoading } from "../../providers/useSwallowLoading";
import { useUserProvider } from "../../providers/useUserProvider";
import { createUser } from "../../api/users";
import MaterialIcon from "../MaterialIcon";
import { PASSWORD_REGEX } from "../../config/regex";
import { uploadDocument } from "../../api/documents";
import { uploadContract } from "../../api/contracts";

type FormValues = {
    nome: string;
    cognome: string;
    email: string;
    codice_fiscale: string;
    data_nascita: string;
    password: string;
    password_confirm: string;
    tipi_utenti_id_tipi_utenti: number;
    listini_id_listino: number;
    telefono: string;
    indirizzo_residenza: string;
    codice_regione: string;
    sigla_provincia: string;
    comune_residenza: string;
    cap_residenza: string;
    piva?: string;
    fl_accetto_privacy_termini: boolean;
    fl_accetto_privacy_utente: boolean;
    privacy_policy?: FileList;
    contract?: FileList;
    fl_accetto_termini?: 0 | 1;
    fl_accetto_privacy?: 0 | 1;
    codice_univoco?: string;
    ragione_sociale: string;
    pec?: string;
};

const DialogUserCreate = forwardRef((props: { onUpdate: (shouldReload: boolean) => void }, ref) => {
    const { register, handleSubmit, getValues, setValue, formState, reset, control } = useForm<FormValues>();
    const { errors } = formState;
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const { user } = useUserProvider();

    const [catalogs, setCatalogs] = useState<any[]>([]);
    const [regions, setRegions] = useState<any[]>([]);
    const [provinces, setProvinces] = useState<any[]>([]);
    const [cities, setCities] = useState<any[]>([]);
    const [caps, setCaps] = useState<any[]>([]);
    const [phonePrefixes, setPhonePrefixes] = useState<any[]>([]);
    const [phonePrefix, setPhonePrefix] = useState<string>('+39');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const [open, setOpen] = useState(false);

    const fl_accetto_privacy_utente = useWatch({
        control,
        name: 'fl_accetto_privacy_utente',
        defaultValue: false,
    });

    useImperativeHandle(ref, () => ({
        open: async (initialData?: FormValues) => {
            reset(initialData);
            setPhonePrefix('+39');
            setPhoneNumber('');
            setProvinces([]);
            setCities([]);
            setCaps([]);
            setCatalogs([]);

            if (initialData?.tipi_utenti_id_tipi_utenti) {
                await handleRoleChange({ target: { value: initialData.tipi_utenti_id_tipi_utenti } } as any);
            }

            setOpen(true);
        },
        close: () => {
            setOpen(false);
            resetForm();
        },
    }));

    useEffect(() => {
        openLoadingDialog();
        Promise.all([loadRegions(), loadPrefixes(), loadCatalogs()])
            .then(([regions, prefixes, catalogs]) => {
                setRegions(regions);
                setPhonePrefixes(prefixes);
                setCatalogs(catalogs);
            })
            .catch((error: any) => {
                enqueueSnackbar("Errore durante l'ottenimento dei dati", { variant: 'error' });
            })
            .finally(() => {
                closeLoadingDialog();
            });
    }, []);

    useEffect(() => {
        setValue('telefono', `${phonePrefix} ${phoneNumber}`);
    }, [phonePrefix, phoneNumber, setValue]);

    const onSubmit = async () => {
        openLoadingDialog();
        try {
            const data: any = getValues();
            data.telefono = `${phonePrefix} ${phoneNumber}`;

            console.log('onSubmit', data);

            data.fl_accetto_termini = 1;
            data.fl_accetto_privacy = 1;

            if (user.userType === 2) {
                data.id_utente_infermiere = user.userId!;
            }

            const response = await createUser({
                ...data,
                fl_accetto_privacy_utente: data.privacy_policy?.length && data.privacy_policy[0] ? 1 : 0,
            });

            const id_utente = response.data.id_utente;

            if (fl_accetto_privacy_utente && data.privacy_policy?.length && data.privacy_policy[0] && data.tipi_utenti_id_tipi_utenti === 5) {
                const file = data.privacy_policy[0];
                await uploadDocument({
                    file,
                    id_utente,
                    id_categoria: 3,
                });
            }

            if (data.contract?.length && data.contract[0] && [2, 3, 4].includes(data.tipi_utenti_id_tipi_utenti)) {
                const file = data.contract[0];
                await uploadContract({
                    file,
                    id_utente
                });
            }

            enqueueSnackbar('Utente creato con successo', { variant: 'success' });

            handleClose();
            props.onUpdate(true);
        } catch (error: any) {
            if (error.response?.data?.message) {
                enqueueSnackbar(error.response.data.message, { variant: 'error' });
            } else {
                enqueueSnackbar('Errore durante la creazione dell\'utente', { variant: 'error' });
            }
        } finally {
            closeLoadingDialog();
        }
    }

    const handleClose = () => {
        setOpen(false);
        resetForm();
    }

    const resetForm = () => {
        reset({});
        setIsPasswordVisible(false);  // Reset password visibility
    }

    const loadRegions = async () => {
        const response = await getRegions();
        return response.regioni;
    };

    const loadPrefixes = async () => {
        const response = await getPrefixes();
        return response.prefissi;
    }

    const loadCatalogs = async () => {
        const response = await getCatalogs({ tipo_profilo: getValues('tipi_utenti_id_tipi_utenti'), fl_attivo: 1 });
        return response.listini;
    }

    const handleRoleChange = async (e: React.ChangeEvent<{ value: number }>) => {
        try {
            const roleValue = e.target.value;
            setValue('tipi_utenti_id_tipi_utenti', roleValue);

            const response = await loadCatalogs();
            setCatalogs(response);
        } catch (error) {
            enqueueSnackbar('Errore durante il caricamento dei listini', { variant: 'error' });
        }
    };

    const handleRegionChange = async (e: React.ChangeEvent<{ value: string }>) => {
        const value = e.target.value as string;
        setValue('codice_regione', value);

        try {
            const response = await getProvinces({ codice_regione: value });
            setProvinces(response.province);
            setCities([]);
            setCaps([]);
        } catch (error) {
            enqueueSnackbar('Errore durante il caricamento delle province', { variant: 'error' });
        }
    }

    const handleProvinceChange = async (e: React.ChangeEvent<{ value: string }>) => {
        const value = e.target.value as string;
        setValue('sigla_provincia', value);

        try {
            const response = await getCities({ sigla_provincia: value });
            setCities(response.comuni);
            setCaps([]);
        } catch (error) {
            enqueueSnackbar('Errore durante il caricamento dei comuni', { variant: 'error' });
        }
    }

    const handleCityChange = async (e: React.ChangeEvent<{ value: string }>) => {
        const value = e.target.value as string;
        setValue('comune_residenza', value);

        try {
            const response = await getCaps({ codice_istat: value });
            setCaps(response.cap);
        } catch (error) {
            enqueueSnackbar('Errore durante il caricamento dei CAP', { variant: 'error' });
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit(onSubmit),
            }}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "48rem",  // Set your width here
                    },
                },
            }}>
            <DialogTitle>Crea un nuovo {user.userType === 2 ? 'paziente' : 'utente'}</DialogTitle>
            <DialogContent>
                <DialogContentText marginBottom="2rem">Compila il form per creare l'utente.</DialogContentText>
                <Grid container rowSpacing="1rem" columnSpacing="1rem">
                    {user.userType === 1 && (
                        <>
                            <Grid item xs={6}>
                                <FormControl fullWidth error={!!errors.tipi_utenti_id_tipi_utenti}>
                                    <InputLabel htmlFor="ruolo">Ruolo</InputLabel>
                                    <Select
                                        id="ruolo"
                                        label="Ruolo"
                                        value={getValues('tipi_utenti_id_tipi_utenti')}
                                        {...register("tipi_utenti_id_tipi_utenti", {
                                            required: true,
                                            onChange: handleRoleChange
                                        })}>
                                        <MenuItem value={1}>Amministratore</MenuItem>
                                        <MenuItem value={2}>Infermiere</MenuItem>
                                        <MenuItem value={3}>Medico</MenuItem>
                                        <MenuItem value={4}>Palestra</MenuItem>
                                        <MenuItem value={5}>Paziente</MenuItem>
                                        <MenuItem value={6}>Farmacia</MenuItem>
                                    </Select>
                                    {!!errors.tipi_utenti_id_tipi_utenti && (
                                        <FormHelperText>{errors.tipi_utenti_id_tipi_utenti.message}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </>
                    )}
                    {user.userType === 1 && (
                        <Grid item xs={6}>
                            <FormControl fullWidth error={!!errors.listini_id_listino}>
                                <InputLabel htmlFor="listino">Listino</InputLabel>
                                <Select
                                    id="listino"
                                    label="Listino"
                                    defaultValue={getValues('listini_id_listino')}
                                    {...register("listini_id_listino", {
                                        required: true,
                                        onChange: () => null // Catalog change
                                    })}
                                >
                                    {catalogs.map((item) => (
                                        <MenuItem key={item.id_listino} value={item.id_listino}>{item.nome_listino}</MenuItem>
                                    ))}
                                    {catalogs.length === 0 && (
                                        <MenuItem disabled>Nessun listino disponibile</MenuItem>
                                    )}
                                </Select>
                                {!!errors.listini_id_listino && (
                                    <FormHelperText>{errors.listini_id_listino.message}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item xs={4}>
                        <FormControl fullWidth error={!!errors.nome}>
                            <InputLabel htmlFor="nome">{[4, 6].includes(getValues('tipi_utenti_id_tipi_utenti')) ? "Nome Rappresentante Legale" : "Nome"}</InputLabel>
                            <OutlinedInput
                                id="nome"
                                label={[4, 6].includes(getValues('tipi_utenti_id_tipi_utenti')) ? "Nome Rappresentante Legale" : "Nome"}
                                {...register("nome", {
                                    required: "Il campo è obbligatorio",
                                    validate: value => value.trim().length > 0 || 'Il campo è obbligatorio'
                                })}
                            />
                            {!!errors.nome && (
                                <FormHelperText>{errors.nome.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth error={!!errors.cognome}>
                            <InputLabel htmlFor="cognome">{[4, 6].includes(getValues('tipi_utenti_id_tipi_utenti')) ? "Cognome Rappresentante Legale" : "Cognome"}</InputLabel>
                            <OutlinedInput
                                id="cognome"
                                label={[4, 6].includes(getValues('tipi_utenti_id_tipi_utenti')) ? "Cognome Rappresentante Legale" : "Cognome"}
                                {...register("cognome", {
                                    required: "Il campo è obbligatorio",
                                    validate: value => value.trim().length > 0 || 'Il campo è obbligatorio'
                                })}
                            />
                            {!!errors.cognome && (
                                <FormHelperText>{errors.cognome.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    {[4, 6].includes(getValues('tipi_utenti_id_tipi_utenti')) && (
                        <Grid item xs={4}>
                            <FormControl fullWidth error={!!errors.ragione_sociale}>
                                <InputLabel htmlFor="ragione_sociale">Ragione Sociale</InputLabel>
                                <OutlinedInput
                                    id="ragione_sociale"
                                    label="Ragione Sociale"
                                    {...register("ragione_sociale", {
                                        required: "Il campo è obbligatorio",
                                        validate: value => value!.trim().length > 0 || 'Il campo è obbligatorio'
                                    })}
                                />
                                {!!errors.ragione_sociale && (
                                    <FormHelperText>{errors.ragione_sociale.message}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    )}
                    {[1, 2, 3, 5].includes(getValues('tipi_utenti_id_tipi_utenti')) && (
                        <Grid item xs={4}>
                            <FormControl fullWidth error={!!errors.data_nascita}>
                                <TextField
                                    id="data_nascita"
                                    label="Data di Nascita"
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true, // Fa sì che l'etichetta si comporti correttamente quando c'è un valore
                                    }}
                                    {...register("data_nascita", {
                                        required: "Il campo è obbligatorio",
                                        validate: (value) => {
                                            const date = new Date(value);

                                            if (date.toString() === 'Invalid Date') {
                                                return 'La data di nascita non è valida';
                                            }

                                            if (date > new Date()) {
                                                return 'La data di nascita non può essere futura';
                                            }

                                            return true;
                                        }
                                    })}
                                />
                                {!!errors.data_nascita && (
                                    <FormHelperText>{errors.data_nascita.message}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item xs={6}>
                        <FormControl fullWidth error={!!errors.codice_fiscale}>
                            <InputLabel htmlFor="codice_fiscale">{[4, 6].includes(getValues('tipi_utenti_id_tipi_utenti')) ? "Codice Fiscale Rappresentante Legale" : "Codice Fiscale"}</InputLabel>
                            <OutlinedInput
                                id="codice_fiscale"
                                label={[4, 6].includes(getValues('tipi_utenti_id_tipi_utenti')) ? "Codice Fiscale Rappresentante Legale" : "Codice Fiscale"}
                                {...register("codice_fiscale", {
                                    required: "Il campo è obbligatorio",
                                    validate: value => value.trim().length > 0 || 'Il campo è obbligatorio'
                                })}
                            />
                            {!!errors.codice_fiscale && (
                                <FormHelperText>{errors.codice_fiscale.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth error={!!errors.telefono}>
                            <InputLabel htmlFor="telefono">Telefono</InputLabel>
                            <OutlinedInput
                                id="telefono"
                                label="Telefono"
                                value={phoneNumber}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Select
                                            id="prefisso"
                                            label="Prefisso"
                                            value={phonePrefix}
                                            onChange={(e) => setPhonePrefix(e.target.value)}
                                            variant="standard"
                                            displayEmpty>
                                            {phonePrefixes.map((item) => (
                                                <MenuItem key={item.id} value={`+${item.phonecode}`}>+{item.phonecode} ({item.iso})</MenuItem>
                                            ))}
                                        </Select>
                                    </InputAdornment>
                                }
                                {...register("telefono", {
                                    required: "Il campo è obbligatorio",
                                    validate: (value) => {
                                        if (value.trim().length === 0) {
                                            return 'Il campo è obbligatorio';
                                        }
                                        if (value.trim().length < 6 || value.trim().length > 15) {
                                            return 'Il numero di telefono non è valido';
                                        }

                                        const [prefix, number] = value.split(' ');

                                        if (!/^\d+$/.test(number) || !/^\+\d+$/.test(prefix)) {
                                            return 'Il numero di telefono non è valido';
                                        }

                                        return true;
                                    },
                                    onChange: (e) => setPhoneNumber(e.target.value)
                                })}
                            />
                            {!!errors.telefono && (
                                <FormHelperText>{errors.telefono.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth error={!!errors.codice_regione}>
                            <InputLabel htmlFor="codice_regione">Regione</InputLabel>
                            <Select
                                id="codice_regione"
                                label="Regione"
                                value={getValues('codice_regione')}
                                {...register("codice_regione", {
                                    required: "Il campo è obbligatorio",
                                    onChange: handleRegionChange
                                })}>
                                {regions.map((item) => (
                                    <MenuItem key={item.codice_regione} value={item.codice_regione}>{item.denominazione_regione}</MenuItem>
                                ))}
                            </Select>
                            {!!errors.codice_regione && (
                                <FormHelperText>{errors.codice_regione.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth error={!!errors.sigla_provincia}>
                            <InputLabel htmlFor="sigla_provincia">Provincia</InputLabel>
                            <Select
                                id="sigla_provincia"
                                label="Provincia"
                                value={getValues('sigla_provincia')}
                                disabled={provinces?.length === 0}
                                {...register("sigla_provincia", {
                                    required: "Il campo è obbligatorio",
                                    onChange: handleProvinceChange
                                })}>
                                {provinces.map((item) => (
                                    <MenuItem key={item.sigla_provincia} value={item.sigla_provincia}>{item.denominazione_provincia}</MenuItem>
                                ))}
                            </Select>
                            {!!errors.sigla_provincia && (
                                <FormHelperText>{errors.sigla_provincia.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth error={!!errors.comune_residenza}>
                            <InputLabel htmlFor="codice_istat">Comune</InputLabel>
                            <Select
                                id="codice_istat"
                                label="Comune"
                                value={getValues('comune_residenza')}
                                disabled={cities?.length === 0}
                                {...register("comune_residenza", {
                                    required: "Il campo è obbligatorio",
                                    onChange: handleCityChange
                                })}>
                                {cities.map((item) => (
                                    <MenuItem key={item.codice_istat} value={item.codice_istat}>{item.denominazione_ita}</MenuItem>
                                ))}
                            </Select>
                            {!!errors.comune_residenza && (
                                <FormHelperText>{errors.comune_residenza.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth error={!!errors.cap_residenza}>
                            <InputLabel htmlFor="cap">CAP</InputLabel>
                            <Select
                                id="cap"
                                label="CAP"
                                defaultValue={getValues('cap_residenza')}
                                disabled={caps?.length === 0}
                                {...register("cap_residenza", {
                                    required: "Il campo è obbligatorio",
                                })}>
                                {caps.map((item) => (
                                    <MenuItem key={item.cap} value={item.cap}>{item.cap}</MenuItem>
                                ))}
                            </Select>
                            {!!errors.cap_residenza && (
                                <FormHelperText>{errors.cap_residenza.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={(getValues('tipi_utenti_id_tipi_utenti') === 2 || getValues('tipi_utenti_id_tipi_utenti') === 3 || getValues('tipi_utenti_id_tipi_utenti') === 4) ? 4 : 8}>
                        <FormControl fullWidth error={!!errors.indirizzo_residenza}>
                            <InputLabel htmlFor="indirizzo_residenza">{[4, 6].includes(getValues('tipi_utenti_id_tipi_utenti')) ? "Sede Legale" : "Indirizzo di residenza"}</InputLabel>
                            <OutlinedInput
                                id="indirizzo_residenza"
                                label={[4, 6].includes(getValues('tipi_utenti_id_tipi_utenti')) ? "Sede Legale" : "Indirizzo di residenza"}
                                {...register("indirizzo_residenza", {
                                    required: "Il campo è obbligatorio",
                                    validate: value => value.trim().length > 0 || 'Il campo è obbligatorio'
                                })}
                            />
                            {!!errors.indirizzo_residenza && (
                                <FormHelperText>{errors.indirizzo_residenza.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    {(getValues('tipi_utenti_id_tipi_utenti') === 2 || getValues('tipi_utenti_id_tipi_utenti') === 3 || getValues('tipi_utenti_id_tipi_utenti') === 4) && (
                        <Grid item xs={4}>
                            <FormControl fullWidth error={!!errors.piva}>
                                <InputLabel htmlFor="piva">Partita IVA</InputLabel>
                                <OutlinedInput
                                    id="piva"
                                    label="Partita IVA"
                                    {...register("piva", {
                                    })}
                                />
                                {!!errors.piva && (
                                    <FormHelperText>{errors.piva.message}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item xs={[2, 3, 4].includes(getValues('tipi_utenti_id_tipi_utenti')) ? 6 : 12}>
                        <FormControl fullWidth error={!!errors.email}>
                            <InputLabel htmlFor="email">Email</InputLabel>
                            <OutlinedInput
                                id="email"
                                label="Email"
                                {...register("email", {
                                    required: "Il campo è obbligatorio",
                                    validate: value => value.trim().length > 0 || 'Il campo è obbligatorio'
                                })}
                            />
                            {!!errors.email && (
                                <FormHelperText>{errors.email.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    {[2, 3, 4, 6].includes(getValues('tipi_utenti_id_tipi_utenti')) && (
                        <Grid item xs={6}>
                            <FormControl fullWidth error={!!errors.codice_univoco}>
                                <InputLabel htmlFor="codice_univoco">Codice Univoco</InputLabel>
                                <OutlinedInput
                                    id="codice_univoco"
                                    label="Codice Univoco"
                                    {...register("codice_univoco", {

                                    })}
                                />
                                {!!errors.codice_univoco && (
                                    <FormHelperText>{errors.codice_univoco.message}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    )}
                    {[2, 3, 4, 6].includes(getValues('tipi_utenti_id_tipi_utenti')) && (
                        <Grid item xs={6}>
                            <FormControl fullWidth error={!!errors.pec}>
                                <InputLabel htmlFor="pec">PEC</InputLabel>
                                <OutlinedInput
                                    id="pec"
                                    label="PEC"
                                    {...register("pec", {

                                    })}
                                />
                                {!!errors.pec && (
                                    <FormHelperText>{errors.pec.message}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item xs={6}>
                        <FormControl fullWidth error={!!errors.password}>
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <OutlinedInput
                                id="password"
                                label="Password"
                                type={isPasswordVisible ? 'text' : 'password'}
                                {...register("password", {
                                    required: "Il campo è obbligatorio",
                                    validate: value => value!.trim().length > 0 || 'Il campo è obbligatorio',
                                    pattern: PASSWORD_REGEX
                                })}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                                            edge="end">
                                            <MaterialIcon icon={isPasswordVisible ? 'visibility' : 'visibility_off'} />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {!!errors.password && (
                                <FormHelperText>{errors.password.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth error={!!errors.password_confirm}>
                            <InputLabel htmlFor="password_confirm">Conferma Password</InputLabel>
                            <OutlinedInput
                                id="password_confirm"
                                label="Conferma Password"
                                type={isPasswordVisible ? 'text' : 'password'}
                                {...register("password_confirm", {
                                    required: "Il campo è obbligatorio",
                                    validate: (value) => {
                                        if (value?.trim().length === 0) {
                                            return 'Il campo è obbligatorio';
                                        }

                                        return value === getValues('password') || 'Le password non corrispondono';
                                    },
                                })}
                            />
                            {!!errors.password_confirm && (
                                <FormHelperText>{errors.password_confirm.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl error={!!errors.fl_accetto_privacy_termini} component="fieldset">
                            <Controller
                                name="fl_accetto_privacy_termini"
                                control={control}
                                defaultValue={false}
                                rules={{ required: "Devi accettare i termini e le condizioni per la registrazione dell'utente" }}
                                render={({ field }) => (
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value} />}
                                        label={
                                            <Typography variant="body2">
                                                Dichiaro, sotto la mia piena responsabilità, di aver personalmente raccolto e verificato i dati dell'utente.
                                            </Typography>
                                        }
                                    />
                                )}
                            />
                            {errors.fl_accetto_privacy_termini && (
                                <FormHelperText>{errors.fl_accetto_privacy_termini.message}</FormHelperText>
                            )}
                        </FormControl>
                        {getValues('tipi_utenti_id_tipi_utenti') === 5 && (
                            <>
                                <FormControl error={!!errors.fl_accetto_privacy_utente} component="fieldset" sx={{ marginTop: 2 }}>
                                    <Controller
                                        name="fl_accetto_privacy_utente"
                                        control={control}
                                        defaultValue={false}
                                        rules={{
                                            validate: (value) => {
                                                if (!value && !getValues('privacy_policy')?.length) {
                                                    return "Devi confermare di esserti accertato che l'utente abbia sottoscritto la Privacy Policy o caricare il file.";
                                                }
                                                return true;
                                            }
                                        }}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        {...field}
                                                        checked={field.value} />}
                                                label={
                                                    <Typography variant="body2">
                                                        Dichiaro, sotto la mia piena responsabilità, di essermi personalmente accertato che l'utente abbia sottoscritto in forma digitale la Privacy Policy, previa la registrazione al Portale - in alternativa, caricare la Privacy Policy firmata.
                                                    </Typography>
                                                }
                                            />
                                        )}
                                    />
                                    {errors.fl_accetto_privacy_utente && (
                                        <FormHelperText>{errors.fl_accetto_privacy_utente.message}</FormHelperText>
                                    )}
                                </FormControl>
                                {!fl_accetto_privacy_utente && (
                                    <FormControl fullWidth sx={{ marginTop: "1rem" }} error={!!errors.privacy_policy}>
                                        <TextField
                                            id="privacy_policy"
                                            label="Privacy Policy"
                                            type="file"
                                            InputLabelProps={{
                                                shrink: true, // Fa sì che l'etichetta si comporti correttamente quando c'è un valore
                                            }}
                                            {...register('privacy_policy', {
                                                required: 'Devi caricare la Privacy Policy',
                                                validate: (value: FileList | undefined) => {
                                                    if (!value) {
                                                        return "Il campo è obbligatorio";
                                                    }

                                                    if (value.length === 0) {
                                                        return "Il campo è obbligatorio";
                                                    }

                                                    if (value.length > 1) {
                                                        return "Puoi caricare un solo file";
                                                    }

                                                    return value[0].size < 10 * 1024 * 1024 || 'Il file deve essere inferiore a 10MB';
                                                }
                                            })}
                                        />
                                        {errors.privacy_policy && (
                                            <FormHelperText>{errors.privacy_policy.message}</FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            </>
                        )}
                    </Grid>
                    {[2, 3, 4].includes(getValues('tipi_utenti_id_tipi_utenti')) && (
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ marginTop: "1rem" }} error={!!errors.contract}>
                                <TextField
                                    id="contract"
                                    label="Contratto"
                                    type="file"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...register('contract', {
                                        required: "Il campo è obbligatorio",
                                        validate: (value: FileList | undefined) => {
                                            if (!value) {
                                                return "Il campo è obbligatorio";
                                            }

                                            if (value.length > 1) {
                                                return "Puoi caricare un solo file";
                                            }

                                            return value[0].size < 10 * 1024 * 1024 || 'Il file deve essere inferiore a 10MB';
                                        }
                                    })}
                                />
                                {errors.contract && (
                                    <FormHelperText>{errors.contract.message}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Annulla</Button>
                <Button variant="contained" type="submit" disabled={formState.isSubmitting}>Salva</Button>
            </DialogActions>
        </Dialog >
    );
});

export default DialogUserCreate;
